import "./footer.css";
import discord_img from "../images/discord.png";
import twitter_img from "../images/twitter.png";

export const Footer = ({}) => {
  return (
    <>
      <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
        <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
          <div className="text-dark order-2 order-md-1 m-auto">

            <a
              href="http://thevault-x.com"
              target="_blank"
              className="powered-by"
              rel="noreferrer"
            >
              Powered By Vault-X
            </a>
            <div className="text-center">
              <a href="https://discord.com/invite/CaSVfkCbvc" className="social-container" target="_blank">
                <img className="social-icon" src={discord_img} />
              </a>
              <a href="https://twitter.com/Raffle_X_live" className="social-container" target="_blank">
                <img className="pl-5 social-icon" src={twitter_img} />
              </a>
            </div>
          </div>

          {/* <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
            <li className="menu-item">
              <a
                href="https://keenthemes.com"
                target="_blank"
                className="menu-link px-2"
              >
                About
              </a>
            </li>
            <li className="menu-item">
              <a
                href="https://devs.keenthemes.com"
                target="_blank"
                className="menu-link px-2"
              >
                Support
              </a>
            </li>
            <li className="menu-item">
              <a
                href="https://1.envato.market/EA4JP"
                target="_blank"
                className="menu-link px-2"
              >
                Purchase
              </a>
            </li>
          </ul> */}
        </div>
      </div>
    </>
  );
};
