import React, { useEffect, useState, useCallback } from "react";

import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { useLocation, useNavigate, Link } from "react-router-dom";

import { PublicKey } from "@solana/web3.js";
import "./leader-board.css";
import { PreLoader } from "../components/pre-loader";
import { Spinner } from "react-bootstrap";

export const LeaderBoard = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isBuyerLoading, setIsBuyerLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [topRaffler, setTopRaffler] = useState([]);
  const [topBuyer, setTopBuyer] = useState([]);
  const [rafflerFilterType, setRafflerFilterType] = useState("raffle_created");
  const [raffleBuyerFilterType, setRaffleBuyerFilterType] =
    useState("raffle_bought");

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  useEffect(() => {
    let url = window.location.href.split("/");
    if (url.length > 2) {
      if (url[3] === "leaderboard") {
        get_top_raffler("raffle_created");
        get_top_buyer("ticket_sold");
      }
    }
    // get_dao_by_id();
  }, [location]);

  const get_top_raffler = async (by) => {
    setIsLoading(true);
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + "topRafflers",
        {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            sort_by: by,
          }),
        }
      );
      if (response.status === 200) {
        setIsLoading(false);
        let data = await response.json();
        console.log(data);
        setTopRaffler(data);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const get_top_buyer = async (by) => {
    setIsBuyerLoading(true);
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + "topRaffleBuyers",
        {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            sort_by: by,
          }),
        }
      );
      if (response.status === 200) {
        setIsBuyerLoading(false);
        let data = await response.json();
        console.log(data);
        setTopBuyer(data);
      }
    } catch (e) {
      setIsBuyerLoading(false);
      console.log(e);
    }
  };

  let onRafflerFilterChange = async (e) => {
    setRafflerFilterType(e);
    get_top_raffler(e);
  };

  let onRafflerBuyerFilterChange = async (e) => {
    setRaffleBuyerFilterType(e);
    get_top_buyer(e);
  };

  const get_whitelst_data = async (address) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getWhitelist/" + address,
        { mode: "cors" }
      );

      const data = await response.json();
      if (response.status === 404) {
        navigate("/individual/" + address);
      }
      if (response.status === 200) {
      }
      if (data["addressType"] !== "") {
        if (data["addressType"] === "dao") {
          navigate("/dao/" + data["daoUrl"]);
        }
      }

      setIsLoading(false);
      return data;
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  return (
    <>

      <div
        id="home_content_container"
        className="d-flex flex-column flex-column-fluid"
      >
        <div className="container-xxl" id="kt_content">
          <div className="row featured-row">
            <div className="col-xl-8">
              <h3 className="card-title align-items-start flex-column">
                <span className="fw-bold text-white featured-raffle-text">
                  Leaderboard
                </span>
              </h3>
            </div>

            <div className="col-xl-4">
              <div className="card-toolbar mb-10"></div>
            </div>
          </div>
          <div className="row featured-row">
            <div className="col-xl-6">
              <div className="leaderboard">
                <div className="headerv">
                  <h1 className="leaderboard__title">
                    <span className="leaderboard__title--top">
                      Top Rafflers
                    </span>
                  </h1>
                  {/* <div className="select-dropdown">
                    <select
                      onChange={(e) => onRafflerFilterChange(e.target.value)}
                    >
                      <option value="raffle_created">Raffle Created</option>
                      <option value="ticket_sold">Ticket Sold</option>
                      <option value="vol">Volume</option>
                    </select>
                  </div> */}
                </div>

                <main className="leaderboard__profiles">
                  <article className="leaderboard__head">
                    <span className="head_1">Rank</span>
                    <span className="head_2">User</span>
                    <span className="head_3">Raffles</span>
                    <span className="head_4">Tickets Sold</span>
                    {rafflerFilterType === "vol" && (
                      <span className="head_4">Volume</span>
                    )}
                  </article>
                  {isLoading ?<Spinner className="mx-auto" animation="border" />:""}
                  {topRaffler.map((raffler, index) => (
                    <article
                      className={`${
                        rafflerFilterType === "vol"
                          ? "leaderboard__profile_w_v"
                          : "leaderboard__profile"
                      }`}
                    >
                      <span className="leaderboard__picture">{index + 1}</span>
                      <span
                        className="leaderboard__name"
                        onClick={() =>
                          get_whitelst_data(raffler["creatorWalletAddress"])
                        }
                      >
                        {raffler["twiiterId"] !== "" &&
                        raffler["twiiterId"] !== null
                          ? raffler["twiiterId"]
                          : raffler["creatorWalletAddress"].substring(0, 4) +
                            "...." +
                            raffler["creatorWalletAddress"].slice(-4)}
                      </span>
                      <span className="leaderboard__value">
                        {raffler["raffles"]}
                      </span>
                      <span className="leaderboard__value">
                        {raffler["ticket_bought"]}
                      </span>
                      {rafflerFilterType === "vol" && (
                        <span className="leaderboard__value">
                          {raffler["volume"]}
                        </span>
                      )}
                    </article>
                  ))}
                </main>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="leaderboard">
                <div className="headerv">
                  <h1 className="leaderboard__title">
                    <span className="leaderboard__title--top">
                      Top Raffle Buyers
                    </span>
                  </h1>
                  {/* <div className="select-dropdown">
                    <select
                     onChange={(e) => onRafflerBuyerFilterChange(e.target.value)}>
                      <option value="raffle_bought">Raffle Bought</option>
                      <option value="ticket_bought">Ticket Bought</option>
                      <option value="won">Raffle Won</option>
                      <option value="vol">Volume</option>
                    </select>
                  </div> */}
                </div>

                <main className="leaderboard__profiles">
                  <article className="leaderboard__head">
                    <span className="head_1">Rank</span>
                    <span className="head_2">User</span>
                    <span className="head_3">Raffles</span>
                    <span className="head_4">Tickets</span>
                    <span className="head_4">Won</span>
                  </article>

                  {isBuyerLoading?<Spinner className="mx-auto" animation="border" />:""}
                  {topBuyer.map((buyer, index) => (
                    <article className="leaderboard__profile_buyer">
                      <span className="leaderboard__picture">{index + 1}</span>
                      <span className="leaderboard__name">
                        {buyer["twitter"] !== ""
                          ? buyer["twitter"]
                          : buyer["address"].substring(0, 4) +
                            "...." +
                            buyer["address"].slice(-4)}
                      </span>
                      <span className="leaderboard__value">
                        {buyer["raffles"]}
                      </span>
                      <span className="leaderboard__value">
                        {buyer["ticket_bought"]}
                      </span>
                      <span className="leaderboard__value">{buyer["won"]}</span>
                    </article>
                  ))}
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
