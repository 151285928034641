import React, { FC, useCallback, useEffect, useReducer, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";


import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { Home } from "./pages/home";
import { CreateRaffle } from "./pages/create-raffle";
import { CreateIndividualRaffle } from "./pages/create-individual-raffle";
import { WhiteList } from "./pages/white-list";
import { RaffleDetail } from "./pages/raffle-details";
import { Dao } from "./pages/dao";
import { Individual } from "./pages/individual";
import { Myaccount } from "./pages/my-account";
import { LeaderBoard } from "./pages/leader-board";
import { TwitterAuth } from "./pages/twitter-auth"
import { PageNotFound } from "./pages/page-not-found";
// import { Navigation } from "./Navigation";

import { useConnection, useWallet } from "@solana/wallet-adapter-react";

import { WalletAdapterNetwork, WalletError } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  getTorusWallet,
} from "@solana/wallet-adapter-wallets";
import {
  Connection,
  PublicKey,
  Transaction,
  clusterApiUrl,
  SystemProgram,
  TransactionInstruction,
  LAMPORTS_PER_SOL,
  Keypair,
  sendAndConfirmTransaction,
} from "@solana/web3.js";
import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import * as anchor from "@project-serum/anchor";

const ENDPOINT = process.env.REACT_APP_NOTIFICATION_SERVER_URL;

export interface RoutesProps {
  connection: anchor.web3.Connection;
  provider: any;
}
type DisplayEncoding = "utf8" | "hex";
type PhantomEvent = "disconnect" | "connect" | "accountChanged";
type PhantomRequestMethod =
  | "connect"
  | "disconnect"
  | "signTransaction"
  | "signAllTransactions"
  | "signMessage";

interface ConnectOpts {
  onlyIfTrusted: boolean;
}
interface PhantomProvider {
  publicKey: PublicKey;
  isConnected: boolean | null;
  signTransaction: (transaction: Transaction) => Promise<Transaction>;
  signAndSendTransaction: (transaction: Transaction) => Promise<any>;
  signAllTransactions: (transactions: Transaction[]) => Promise<Transaction[]>;
  signMessage: (
    message: Uint8Array | string,
    display?: DisplayEncoding
  ) => Promise<any>;
  connect: (opts?: Partial<ConnectOpts>) => Promise<{ publicKey: PublicKey }>;
  disconnect: () => Promise<void>;
  on: (event: PhantomEvent, handler: (args: any) => void) => void;
  request: (method: PhantomRequestMethod, params: any) => Promise<unknown>;
}
const getProvider = (): PhantomProvider | undefined => {
  if ("solana" in window) {
    const anyWindow: any = window;
    const provider = anyWindow.solana;
    if (provider.isPhantom) {
      // console.log("provider:"+ provider)
      return provider;
    }
  }
  // window.open("https://phantom.app/", "_blank");
};

const AppRoutes: FC = () => {

  useEffect(() => {}, []);

  return (
    <>
      <div className="d-flex flex-column flex-root">

          <div className="page d-flex flex-row flex-column-fluid">
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
                <Header/>
                <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="/create-raffle" element={<CreateRaffle/>} />
                <Route path="/create-individual" element={<CreateIndividualRaffle/>} />
                <Route path="/raffle/:id" element={<RaffleDetail/>} />
                <Route path="/white-list" element={<WhiteList/>} />
                <Route path="/dao/:id" element={<Dao/>} />
                <Route path="/individual/:id" element={<Individual/>} />
                <Route path="/twitter/validate" element={<TwitterAuth/>} />
                <Route path="/my-account" element={<Myaccount/>} />
                <Route path="/leaderboard" element={<LeaderBoard/>} />

                <Route path="*" element={<PageNotFound />} />
                {/* <Route path="/navigation" element={<Navigation/>} /> */}
                </Routes>
                <Footer/>
            </div>
          </div>


      </div>
    </>
  );
};

export { AppRoutes };
