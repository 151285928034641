import React, { useState, useCallback, useRef } from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import Swal from "sweetalert2";
import { createAddToWLTransaction } from "../util/raffle";
import { Navigate } from "react-router-dom";
import S3 from "react-aws-s3";

import "./white-list.css";

export const WhiteList = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const [whitelist_value, setwhitelist_value] = useState("");
  const [whitelist_type, setwhitelist_type] = useState("dao");
  const [dao_name, setdao_name] = useState("");
  const [token_name, settoken_name] = useState("");
  const [project_name, setproject_name] = useState("");
  const [token_decimal, settoken_decimal] = useState<any | null>(null);
  const [dao_url, setdao_url] = useState("");
  const [dao_description, setdao_description] = useState("");
  const [twitter_handle, settwitter_handle] = useState("");
  const [isdaoValid, setisdaoValid] = useState(false);
  const [file, setFile] = useState();
  const fileInput: any = useRef();
  const [fileNameFrReq, setFileNameFrReq] = useState();
  let onWhiteListTypeChange = async (e) => {
    setwhitelist_type(e);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    if (whitelist_value === "") {
      Swal.fire({
        text: "Address is empty",
        confirmButtonText: "Close",
      });
      return;
    }
    if (whitelist_type !== "dao" && whitelist_type !== "token" && whitelist_type !== "nft") {
      let res = await AddToWL(
        whitelist_value,
        whitelist_type,
        dao_url,
        dao_name,
        token_name,
        project_name,
        token_decimal,
        dao_description,
        twitter_handle
      );
      console.log(res);
    } else if (whitelist_type === "nft") {
      if (twitter_handle !== "") {
        localStorage.setItem("newFileName", "");
        let res = await AddToWL(
          whitelist_value,
          whitelist_type,
          dao_url,
          dao_name,
          token_name,
          project_name,
          token_decimal,
          dao_description,
          twitter_handle
        );
      } else {
        Swal.fire({
          text: "Twitter Handle is empty",
          confirmButtonText: "Close",
        });
        return;
      }
    } else if (whitelist_type === "token") {
      if (token_name !== "" && token_decimal !== null) {
        let decimal = Number(token_decimal)
        let res = await AddToWL(
          whitelist_value,
          whitelist_type,
          dao_url,
          dao_name,
          token_name,
          project_name,
          decimal,
          dao_description,
          twitter_handle
        );
      } else {
      Swal.fire({
        text: "Token name is empty",
        confirmButtonText: "Close",
      });
      return;
    }
  } else if (isdaoValid) {
      if (whitelist_value !== "") {
        try {
          const response = await fetch(
            process.env.REACT_APP_API_URL + "check/" + whitelist_value,
            { mode: "cors" }
          );
          const data = await response.json();
          console.log(data);
          if (data.length) {
            if (data[0].raffleType === "dao") {
              Swal.fire({
                text: "This address already have DAO",
                confirmButtonText: "Close",
              });
            }
            if (data[0].raffleType === "individual") {
              Swal.fire({
                text: "This address already have individual raffle",
                confirmButtonText: "Close",
              });
            }
          } else {
            let res = await AddToWL(
              whitelist_value,
              whitelist_type,
              dao_url,
              dao_name,
              token_name,
              project_name,
              token_decimal,
              dao_description,
              twitter_handle
            );
            console.log(res);
          }
        } catch (e) {}
      } else {
        Swal.fire({
          title: "Empty or invalid form fields",
          text: "Please review all the fields",
          confirmButtonText: "Close",
        });
      }
    } else {
      Swal.fire({
        title: "DAO name not vaild.Please enter valid DAO name",
        confirmButtonText: "Close",
      });
    }
  };

  const sendAndConfirmTransaction = useCallback(
    async (transaction) => {
      let { blockhash } = await connection.getRecentBlockhash();
      transaction.feePayer = publicKey!;
      transaction.recentBlockhash = blockhash;

      let signature = await sendTransaction(transaction, connection, {skipPreflight:false});
      await connection.confirmTransaction(signature, "confirmed");

      console.log(signature);

      // if (onChange) onChange();

      return signature;
    },
    [connection, publicKey, sendTransaction]
  );

  const AddToWL = useCallback(
    async (w_l, w_t, d_u, d_n,t_n,p_n,t_d, d_d, t_h) => {
      if (!publicKey) return;
      console.log(w_l, w_t);
      let t = await sendAndConfirmTransaction(
        await createAddToWLTransaction(
          connection,
          publicKey,
          new PublicKey(w_l)
        )
      );

      if (t) {
        try {
          if (w_t === "nft") {
            uploadToDb(w_l, w_t, d_u, d_n,t_n, p_n,t_d, d_d, t_h);
          } else {
            let file = fileInput!.current.files[0];
            let newFileName;

            if (file !== undefined) {
              let newFileName = fileInput!.current.files[0].name.replace(
                /\..+$/,
                ""
              );
              newFileName = Math.random() + "_" + file.name;
              localStorage.setItem("newFileName", newFileName);
              const config = {
                bucketName: process.env.REACT_APP_BUCKET_NAME,
                //   dirName: process.env.REACT_APP_DIR_NAME /* optional */,
                region: process.env.REACT_APP_REGION,
                accessKeyId: process.env.REACT_APP_ACCESS_ID,
                secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
                s3Url: process.env.REACT_APP_S3URL,
              };

              const ReactS3Client = new S3(config);
              ReactS3Client.uploadFile(file, newFileName).then((data) => {
                if (data.status === 204) {
                  console.log("success");
                  uploadToDb(w_l, w_t, d_u, d_n,t_n, p_n,t_d, d_d, t_h);
                } else {
                  console.log("fail");
                }
              });
            }
          }
        } catch (e) {
          Swal.fire({
            text: "Whitelist has successful. but not updated in database. please ask developer to update.",
            confirmButtonText: "Close",
          });
        }
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  let uploadToDb = async (w_l, w_t, d_u, d_n,t_n, p_n,t_d, d_d, t_h) => {
    console.log(w_l, w_t, d_n, d_d, localStorage.getItem("newFileName"));
    let access_res = await fetch(process.env.REACT_APP_API_URL + "whitelist", {
      method: "POST",
      mode: "cors",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({
        address: w_l,
        addressType: w_t,
        daoUrl: d_u,
        daoName: d_n,
        tokenName:t_n,
        projectName:p_n,
        tokenDecimal:t_d,
        daoDescription: d_d,
        attachemnet: localStorage.getItem("newFileName"),
        twitterId: t_h,
      }),
    });
    let access_key = await access_res.json();
    Swal.fire({
      text: "Whitelist has successful",
      confirmButtonText: "Close",
    });
  };
  const handleImageUpload = (event) => {
    if (event.target.files.length > 0) {
      let fileExtension = event.target.files[0].name.substring(
        event.target.files[0].name.lastIndexOf(".") + 1
      );

      if (
        fileExtension.toLowerCase() === "gif" ||
        fileExtension.toLowerCase() === "jpg" ||
        fileExtension.toLowerCase() === "jpeg" ||
        fileExtension.toLowerCase() === "png" ||
        fileExtension.toLowerCase() === "webp"
      ) {
        setFile(event.target.files[0].name);
      } else {
        event.target.value = null;
        Swal.fire({
          text: "Please select the gif/jpg/jpeg/png format image",
          confirmButtonText: "Close",
        });
      }
    }
  };

  let daoValidation = async (dao_name) => {
    if (dao_name.length > 3) {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "validateDaoName/" + dao_name,
          { mode: "cors" }
        );
        const data = await response.text();
        if (data === "true") {
          setisdaoValid(true);
          setdao_url(dao_name);
        } else {
          setisdaoValid(false);
        }
        console.log(data);
      } catch (e) {}
    }
  };
  return (
    <>
      {publicKey ? (
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-xxl"
        >
          <div
            className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
            id="kt_create_account_stepper"
            data-kt-stepper="true"
          >
            <div className="d-flex flex-column flex-lg-row-fluid py-10">
              <div className="d-flex flex-center flex-column flex-column-fluid">
                <div className="w-lg-650px w-xl-700px p-10 p-lg-15 mx-auto card">
                  <form
                    onSubmit={handleSubmit}
                    className="my-auto pb-5 fv-plugins-bootstrap5 fv-plugins-framework"
                    id="kt_create_account_form"
                  >
                    <div className="w-100">
                      <div className="row fv-row mb-7 fv-plugins-icon-container">
                        <div className="col-12">
                          <label className="form-label mb-3">
                            To Be Whitelist
                          </label>

                          <select
                            className="form-select form-select-solid"
                            name="created_for"
                            onChange={(e) =>
                              onWhiteListTypeChange(e.target.value)
                            }
                          >
                            {/* <option value="seller">Seller</option> */}
                            <option value="dao">DAO</option>
                            <option value="nft">NFT</option>
                            <option value="token">Token</option>
                            <option value="wallet">Wallet</option>
                            <option value="ticket_token">Ticket Token</option>
                          </select>
                        </div>
                        {whitelist_type === "dao" && (
                          <>
                            <div className="col-12 mt-5">
                              <label className="form-label mb-3">DAO URL</label>

                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                onChange={(e) => daoValidation(e.target.value)}
                                placeholder=""
                              />
                              {isdaoValid && (
                                <span className="text-white">DAO valid</span>
                              )}
                            </div>
                            <div className="col-12 mt-5">
                              <label className="form-label mb-3">
                                DAO Name
                              </label>

                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                onChange={(e) => setdao_name(e.target.value)}
                                placeholder=""
                              />
                            </div>
                            <div className="col-12 mt-5">
                              <label className="form-label mb-3">
                                DAO Description
                              </label>

                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                onChange={(e) =>
                                  setdao_description(e.target.value)
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="col-12 mt-5">
                              <label className="form-label mb-3">
                                Twitter Handle
                              </label>

                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                onChange={(e) =>
                                  settwitter_handle(e.target.value)
                                }
                                placeholder=""
                              />
                            </div>
                            <div className="row row-cols-1 row-cols-sm-1 rol-cols-md-1 row-cols-lg-1 mt-5">
                              <div className="col">
                                <div className="image-upload d-flex flex-column fv-row mb-8">
                                  <label className="form-label mb-3">
                                    DAO Image/Gif
                                  </label>
                                  <span className="sub-note ">
                                    Upload image size should be maximum of 2 MB
                                    and 400 X 400 pixels size.
                                  </span>
                                  <input
                                    className="whitlist-image-upload"
                                    type="file"
                                    ref={fileInput}
                                    onChange={(event) =>
                                      handleImageUpload(event)
                                    }
                                    accept="image/*"
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {whitelist_type === "token" && (
                          <>
                             <div className="col-12 mt-5">
                              <label className="form-label mb-3">
                                Project/Category Name
                              </label>

                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                onChange={(e) => setproject_name(e.target.value)}
                                placeholder=""
                              />
                            </div>
                            <div className="col-12 mt-5">
                              <label className="form-label mb-3">
                                Token Name
                              </label>

                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                onChange={(e) => settoken_name(e.target.value)}
                                placeholder=""
                              />
                            </div>
                            <div className="col-12 mt-5">
                              <label className="form-label mb-3">
                                Token Decimal
                              </label>

                              <input
                                type="number"
                                className="form-control form-control-lg form-control-solid"
                                onChange={(e) => settoken_decimal(e.target.value)}
                                placeholder=""
                              />
                            </div>

                            <div className="row row-cols-1 row-cols-sm-1 rol-cols-md-1 row-cols-lg-1 mt-5">
                              <div className="col">
                                <div className="image-upload d-flex flex-column fv-row mb-8">
                                  <label className="form-label mb-3">
                                    Token Image/Gif
                                  </label>
                                  <span className="sub-note ">
                                    Upload image size should be maximum of 2 MB
                                    and 400 X 400 pixels size.
                                  </span>
                                  <input
                                    className="whitlist-image-upload"
                                    type="file"
                                    ref={fileInput}
                                    onChange={(event) =>
                                      handleImageUpload(event)
                                    }
                                    accept="image/*"
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="col-12 mt-5">
                          <label className="form-label mb-3">Address</label>

                          <input
                            type="text"
                            className="form-control form-control-lg form-control-solid"
                            onChange={(e) => setwhitelist_value(e.target.value)}
                            placeholder=""
                          />
                        </div>
                        {whitelist_type === "nft" && (
                          <div className="col-12 mt-5">
                            <label className="form-label mb-3">
                              Twitter Handle
                            </label>

                            <input
                              type="text"
                              className="form-control form-control-lg form-control-solid"
                              onChange={(e) =>
                                settwitter_handle(e.target.value)
                              }
                              placeholder=""
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-primary me-3 text-center"
                        onClick={handleSubmit}
                      >
                        Whitelist
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};
